var site = window.site || {};
var openSalesforceChat = window.openSalesforceChat || function () {};

(function ($, Drupal) {
  Drupal.behaviors.liveChatServiceDrawerV2 = {
    attach: function () {
      var rendered =
        $('.domain-fr.device-mobile').length === 0
          ? site.template.get({ name: 'lc_service_drawer_v2', data: {} })
          : '';
      var $body = $('body');

      $body.append(rendered);

      var $lcServiceDrawer = $('.js-lc_service_drawer');
      var $pageOverlay = $('.lc_service_drawer__overlay');
      var $liveChat = $('.js-live-chat');
      var isDeviceMobile = $('.device-mobile').length > 0;
      const urlParams = new URLSearchParams(window.location.search);
      const hideGNavParam = urlParams.get(Drupal.settings.hideGNav.param);
      const pathName = window.location.pathname;
      const hideGNavPath = Drupal.settings.hideGNav.path;

      function closeLcServiceDrawer() {
        $lcServiceDrawer.slideUp();
        $pageOverlay.slideUp();
      }

      function openLcServiceDrawer() {
        $lcServiceDrawer.slideDown();
        $pageOverlay.slideDown();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat offered',
            event_label: 'click'
          });
        }
      }

      function handleLiveChat() {
        var tokywokyState = typeof toky !== 'undefined' ? toky.state : '';
        var showChat = tokywokyState === 'maximized' && isDeviceMobile === true;
        $liveChat.toggleClass('hidden', showChat);
      }

      $('#toky_container > div > div').each(function () {
        $(this)
          .once()
          .on('click', function () {
            handleLiveChat();
          });
      });

      $liveChat.on('click', function (event) {
        event.stopPropagation();
        event.preventDefault();

        if ($lcServiceDrawer.is(':visible')) {
          closeLcServiceDrawer();
          return;
        }

        if ($body.find('.js-lc_service_drawer').length === 0) {
          $body.append(rendered);
        }
        openLcServiceDrawer();
      });

      $pageOverlay.on('click', function (event) {
        event.stopPropagation();
        closeLcServiceDrawer();
        handleLiveChat();
      });

      $('html').on('click', function (event) {
        if (
          !$(event.target).hasClass('js-lc_service_drawer') &&
          !$(event.target).hasClass('js-live-chat')
        ) {
          closeLcServiceDrawer();
          if (!(hideGNavParam && pathName.includes(hideGNavPath))) {
            handleLiveChat();
          }
        }
      });

      $('.js-lc_beauty_advisor_btn').on('click', function (event) {
        var $this = $(this);
        var $triggerChat;

        event.preventDefault();
        $('.close', 'c-chat-header').trigger('click');
        closeLcServiceDrawer();
        handleLiveChat();
        var $lpmTrigger = $('.LPMcontainer img');
        var $tkTrigger = $('#toky_container img');

        if ($lpmTrigger.length > 0 || $tkTrigger.length > 0) {
          $lpmTrigger.trigger('click');
          if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
            site.track.evtLink({
              event_name: 'live chat interaction',
              event_category: 'live chat interaction',
              event_action: 'chat type selection',
              event_label: 'Beauty Advisor'
            });
          }
          if ($this.hasClass('lp-chat')) {
            $triggerChat = $('.LPMcontainer img');
          } else if ($this.hasClass('tw-chat')) {
            $triggerChat = $('#toky_container img');
          }
          if ($triggerChat.length > 0) {
            $triggerChat.trigger('click');
          }
        }
      });
      $('.js-lc_order_status_btn').on('click', function (event) {
        event.preventDefault();
        $('.lp_minimize').trigger('click');
        closeLcServiceDrawer();
        openSalesforceChat();
        if (typeof site.track !== 'undefined' && typeof site.track.evtLink === 'function') {
          site.track.evtLink({
            event_name: 'live chat interaction',
            event_category: 'live chat interaction',
            event_action: 'chat type selection',
            event_label: 'Customer Service'
          });
        }
      });

      if( hideGNavParam && pathName.includes(hideGNavPath)) {
        $liveChat.addClass('hidden');
      } else {
        $liveChat.removeClass('hidden');
      }

    }
  };
})(jQuery, Drupal);
